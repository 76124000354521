<template>
  <div class="space-y-1">
    <dt class="flex items-end justify-between text-sm font-bold text-gray-700">
      Riwayat Stock
      {{
        filter.period_uuid
          ? getPeriods.data.find((it) => it.id == filter.period_uuid).attributes
              .name
          : getPeriod.data.attributes.name
      }}
      <div>
        <period-select
          v-model="filter.period_uuid"
          v-on:change="handleFilter"
        />
      </div>
    </dt>
    <div class="my-2 flex sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <Datatable
          :total="getStockTrxes.meta.page.total"
          :perPage="getStockTrxes.meta.page.perPage"
          :currentPage="getStockTrxes.meta.page.currentPage"
          @pagechanged="handlePageChangeStockTrx"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-for="(item, index) in [
                    {
                      label: 'Tanggal',
                    },
                    {
                      label: 'Keterangan',
                    },
                    {
                      label: 'Masuk',
                      classes: 'text-right',
                    },
                    {
                      label: 'Keluar',
                      classes: 'text-right',
                    },
                    {
                      label: 'Saldo Stock',
                      classes: 'text-right',
                    },
                  ]"
                  :key="index"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  :class="item.classes"
                >
                  {{ item.label }}
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="!getStockTrxes.data.length">
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  colspan="5"
                >
                  Riwayat Kosong
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                class="bg-white hover:bg-green-100"
                v-for="(data, dataIdx) in getStockTrxes.data"
                :key="data.id"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
                  {{ data.attributes.createdAt | formatDate }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
                  {{ data.attributes.description ?? '-' }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{
                    data.attributes.type === 'in'
                      ? Math.abs(data.attributes.amount)
                      : '-'
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{
                    data.attributes.type === 'out'
                      ? Math.abs(data.attributes.amount)
                      : '-'
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ data.attributes.current_stock | toCurrency }}
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from '@/components/base/Datatable';
import { mapGetters } from 'vuex';
import PeriodSelect from '@/components/period/PeriodSelect.vue';

export default {
  components: { Datatable, PeriodSelect },
  computed: {
    ...mapGetters({
      getStockTrxes: 'stock_trxes/getStockTrxes',
      getPeriod: 'periods/getPeriod',
      getPeriods: 'periods/getPeriods',
    }),
  },
  emits: ['change-page'],
  data() {
    return {
      filter: {
        period_uuid: null,
      },
    };
  },
  methods: {
    handlePageChangeStockTrx(page) {
      this.$emit('change-page', page);
    },
    handleFilter() {
      this.$emit('change-period', this.filter.period_uuid);
    },
  },
};
</script>
